<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'bg-blue-500',
  },
});
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <TnHeader />
    <div class="max-w-app mx-auto w-full flex-1">
      <slot />
    </div>
    <TnFooter class="mt-auto" />
  </div>
</template>
